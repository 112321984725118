import * as Constants from '../constants';
import { useState, useEffect } from 'react';
import Spinner from '../components/Spinner';
import { AiFillMinusSquare } from 'react-icons/ai';
import { AiFillPlusSquare } from 'react-icons/ai';
import { IoMdCheckmarkCircle } from 'react-icons/io';

function GetBread(prop) {

	useEffect(async () => {
		await fetchPublicHolidays();
		fetchBread();
		fetchCategories();
		console.log(loader)
	}, []);

	let arr = [];
	let todayEntries = [];
	let todayEntriesString = '';
	let summary = {};

	const [breads, setBreads] = useState([]);
	const [count, setCount] = useState(1);
	const [apartment, setApartment] = useState('');
	const [orders, setOrders] = useState([]);
	const [categories, setCategories] = useState([]);
	const [category, setCategory] = useState()
	const [loader, setLoader] = useState(false)
	const [publicHoliday, setPublicHoliday] = useState(false);
	let filter;

	const fetchBread = async () => {
		fetch('https://cockpit.dornsberg.net/api/collections/get/bread?token=account-9805cfff4b0ec3f5f36f57910991b1', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				filter: filter
			})
		})
			.then(data => data.json())
			// push to array
			.then(data => {
				for (let i = 0; i < data.entries.length; i++) {
					arr.push(data.entries[i]);
				}
			})
			// sort array by #
			.then(() => {
				arr.sort((a, b) => a.number - b.number);
				setBreads(arr);
				console.log(arr);
			})
			.catch(function () {
				console.log('Fetch error');
			});
	};

	const fetchCategories = async () => {
		fetch('https://cockpit.dornsberg.net/api/collections/collection/bread?token=account-9805cfff4b0ec3f5f36f57910991b1')
			.then(collection => collection.json())
			.then(collection => {
				let filter = collection.fields.filter((field) => field.name === 'category');
				setCategories(filter[0].options.options);

				setCategory(filter[0].options.options[0].value);
			})
			.catch(function () {
				console.log('Fetch error');
			});
	};

	const fetchPublicHolidays = async () => {
		const response = await fetch('https://cockpit.dornsberg.net/api/collections/get/publicHolidays?token=account-9805cfff4b0ec3f5f36f57910991b1');
		const res = await response.json()
		const currentDate = new Date();
		currentDate.setDate(currentDate.getDate() + 1);
		if(res.entries.find((el) => el.date === currentDate.toLocaleDateString('en-CA'))) {
			setPublicHoliday(true);
			return filter = {
				active: true,
				everyDay: true
			}
		}
		else return filter = { active: true };
	};

	if (!breads.length) return <Spinner />;
	else console.log(breads);

	const Category = () => {
		if (!categories.length) return <Spinner />
		else return (
			<div className="bread-categories flex-center flex-wrap">
				{categories.map((cat, index) => {
					let catLang;
					if (prop.lang === 'de') catLang = cat.value.split(', ')[0]
					if (prop.lang === 'it') catLang = cat.value.split(', ')[1]
					if (prop.lang === 'en') catLang = cat.value.split(', ')[2]
					return (
						<button key={index}
							className={cat.value === category ? 'category button2' : 'category button1'}
							onClick={() => setCategory(cat.value)}>
							{catLang}
						</button>
					)
				})}
			</div>
		)
	}

	const BreadList = () => {
		setCount(1);
		return (
			<div className='bread-list flex-center flex-wrap'>
				{publicHoliday && <div className={`c4 tac`}>{Constants.publicHoliday[Constants.lang]}</div>}
				<Category />
				{breads.map((bread, index) => {
					if (bread.category === category) return (
						<div key={index} className={'bread bread-' + (index + 1)}>
							<button className="bread-number flex-column align-center space-between" onClick={() => addToCart(bread.number)}>
								<div className="bread-image-wrapper flex-center align-center">
									<img className="bread-image" src={[Constants.cockpit] + bread.img.path} />
								</div>
								<div className="bread-name t4">{bread['title_' + prop.lang]}</div>
								<div className="bread-price t5">{bread.price} €</div>
							</button>
							{prop.cart.map((cart, i) => {
								if (cart.bread.number === bread.number) return <Counter number={bread.number} />
							})}
						</div>
					)
				})}
				{(prop.backend === true) ? <button className="button1" onClick={() => prop.setTitle('backend') + prop.setPage('backend')}>Alle Bestellungen</button> : ''}
				{(prop.backend === true) ? <button className="button1" onClick={() => prop.setTitle('bestseller') + prop.setPage('bestseller')}>Bestseller</button> : ''}
			</div>
		)
	}

	const Counter = (bread) => (
		<div className="counter">
			{prop.cart.map((cart, i) => {
				if (bread.number === cart.bread.number) return (
					<div className="conversion tac">
						<div className="wrapper flex-center align-center">
							<button className="minus large flex align-center" onClick={() => cartDecrement(i, cart.quantity)}>
								<AiFillMinusSquare color="white" />
							</button>
							<div className="count tac large">{cart.quantity}</div>
							<button className="plus large flex align-center" onClick={() => cartIncrement(i, cart.quantity)}>
								<AiFillPlusSquare color="white" />
							</button>
						</div>
						<button className="delete-article default tar c4" onClick={() => delArticle(i)}>{Constants.deleteLabel[prop.lang]}</button>
					</div>
				)
			})}
		</div>
	)

	const addToCart = (number) => {
		// check if product is already in cart
		let duplicate = 0;
		if (prop.cart.length) {
			prop.cart.map((bread, i) => {
				if (number === bread.bread.number) {
					let copy = JSON.parse(JSON.stringify(prop.cart));
					copy[i].quantity = copy[i].quantity + count
					prop.setCart(copy)
					duplicate++;
				}
				if (duplicate === 0 && i + 1 === prop.cart.length) addNewItemToCart(number);
			})
		}
		else addNewItemToCart(number);
	}
	const addNewItemToCart = (number) => {
		breads.map((bread, i) => {
			if (number === bread.number) {
				prop.setCart([...prop.cart, {
					'bread': bread,
					'quantity': count
				}]);
			}
		})
	}

	const cartIncrement = (i, qnt) => {
		let copy = JSON.parse(JSON.stringify(prop.cart));
		copy[i].quantity = copy[i].quantity + 1
		prop.setCart(copy)
	}
	const cartDecrement = (i, qnt) => {
		if (qnt > 1) {
			let copy = JSON.parse(JSON.stringify(prop.cart));
			copy[i].quantity = copy[i].quantity - 1
			prop.setCart(copy)
		}
	}
	const delArticle = (i) => {
		let copy = JSON.parse(JSON.stringify(prop.cart));
		copy.splice(i, 1);
		prop.setCart(copy)
	}
	const cartSum = prop.cart.reduce((sum, item) => {
		return sum + (Number(item.bread.price.replaceAll(',', '.')) * item.quantity)
	}, 0)
	let cartTotal = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(cartSum);
	const CartDetail = () => {
		console.log(prop.cart)
		if (prop.cart.length) return (
			<div className="cart-detail">
				<div className="cart-product-list">
					{prop.cart.map((cart, i) => (
						<div key={i} className={'cart-product product-' + i + ' flex space-between'}>
							<div className="product-left">
								<div className="product-name">{cart.bread['title_' + prop.lang]}</div>
								<img alt={cart.bread['title_' + prop.lang]} className="product-image" src={[Constants.cockpit] + cart.bread.img.path} />
							</div>
							<div className="product-right flex-column space-between">
								<div className="product-quantity flex align-center">
									<button className="minus large flex align-center" onClick={() => cartDecrement(i, cart.quantity)}>
										<AiFillMinusSquare />
									</button>
									<div className="count tac large">{cart.quantity}</div>
									<button className="plus large flex align-center" onClick={() => cartIncrement(i, cart.quantity)}>
										<AiFillPlusSquare />
									</button>
								</div>
								<div className="product-price tar">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((Number(cart.bread.price.replaceAll(',', '.')) * cart.quantity))}</div>
								<button className="delete-article default tar" onClick={() => delArticle(i)}>{Constants.deleteLabel[prop.lang]}</button>
							</div>
						</div>
					))}
				</div>
				<div className="cart-detail-bottom">
					<div className="total tar">{Constants.total[prop.lang] + ': ' + cartTotal}</div>
				</div>
				<div className="cta-wrapper">
					<div className="cta flex space-between">
						<button className="shop-on button1" onClick={() => {
							prop.setTitle(Constants.productNumb);
							prop.setPage('list');
						}}>
							{Constants.orderOnLabel[prop.lang]}
						</button>
						<button className="checkout button3 t5" onClick={() => {
							prop.setTitle(Constants.checkOutLabel);
							prop.setPage('mail');
						}}>
							{Constants.checkoutLabel[prop.lang]}
						</button>
					</div>
				</div>
			</div>
		)
		else return (
			<div className="cart-detail">
				{Constants.emptyCartLabel[prop.lang]}
			</div>
		)
	}

	// const submitOrder = async () => {
	// 	let data = '';
	// 	prop.cart.map((item, i) => {
	// 		data += (i+1)+'. Produkt: '+item.bread.bread_de+'<br/>Menge: '+item.quantity+'<br/><br/>';
	// 	});
	// 	console.log(data);
	// 	fetch('https://shop.dornsberg.net/php/mailer.php', {
	// 		method: 'post',
	// 		headers: { 'Content-Type': 'application/json' },
	// 		body: JSON.stringify({
	// 			order: data
	// 		})
	// 	})
	// }

	const sendMail = (data) => {
		setLoader(true)
		let order = [];
		for (var i = 0; i < prop.cart.length; i++) {
			order[i] = {
				'name': prop.cart[i].bread.title_de,
				'quantity': prop.cart[i].quantity,
				'number': prop.cart[i].bread.number,
				'erbbrot': prop.cart[i].bread.erbbrot,
				'extraId': prop.cart[i].bread.extraId
			};
		}
		// Push this order to db
		let todayDate = new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.' + new Date().getFullYear();
		fetch('https://cockpit.dornsberg.net/api/collections/save/brotbestellungen?token=account-9805cfff4b0ec3f5f36f57910991b1', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				data: {
					date: todayDate,
					apartment: apartment,
					order: order
				}
			})
		})
			.then(bread => (
				// Get all orders
				fetch('https://cockpit.dornsberg.net/api/collections/get/brotbestellungen?token=account-9805cfff4b0ec3f5f36f57910991b1', {
					method: 'post',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						filter: {
							date: todayDate
						},
					})
				})
			))
			.then(bread => bread.json())
			// push to array
			.then(bread => {
				bread = bread.entries;
				console.log(bread);
				for (let i = 0; i < bread.length; i++) {
					let timestamp = new Date(bread[i]._created * 1000);
					todayEntriesString += '<br/>' + timestamp.toLocaleString('de') + ' Bestellung von Apartment: ' + bread[i].apartment + '<br/>';
					for (let index = 0; index < bread[i].order.length; index++) {
						todayEntriesString += bread[i].order[index].name + ': ' + bread[i].order[index].quantity + '<br/>';
					}	
				}
				// Summary of all orders by bread type
				for (let i = 0; i < bread.length; i++) {
					bread[i].order.forEach(function (d) {
						if (summary.hasOwnProperty(d.name)) {
							summary[d.name] = summary[d.name] + d.quantity;
						} else {
							summary[d.name] = d.quantity;
						}
					})
				}
				summary = JSON.stringify(summary);
				summary = summary.replaceAll(',', '<br/>').replaceAll('{', '').replaceAll('}', '').replaceAll('"', '').replaceAll(':', ': ');
				console.log(summary);
				console.log(todayEntriesString);
			})
			.then(bread => (
				fetch('https://shop.dornsberg.net/php/mailer-bread.php', {
					method: 'post',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						order: data,
						summary: summary,
						all_orders: todayEntriesString
					})
				})
			))
			.then(response => {
				if (!response.ok) {
					throw new Error('no repsonse')
				}
				// setKeycard('success');
				setApartment('success');
				prop.setPage('success');
				setTimeout(function () {
					window.location.reload(true);
				}, 15000);
				setLoader(false)
			})
			.catch(error => {
				console.error('Problem: ', error);
			})
	}

	const CheckOut = () => {
		if (apartment === '') {
			return (
				<div className="checkout tac">
					<div className="check-out large m-b-50">{Constants.chooseApt[prop.lang]}</div>
					<div className="apartments flex-center flex-wrap space-between">
						<button className="apartment medium" onClick={() => setApartment('Frieda')}>Frieda</button>
						<button className="apartment medium" onClick={() => setApartment('Paula')}>Paula</button>
						<button className="apartment medium" onClick={() => setApartment('Martha')}>Martha</button>
						<button className="apartment medium" onClick={() => setApartment('Franzi')}>Franzi</button>
						<button className="apartment medium" onClick={() => setApartment('Luise')}>Luise</button>
						<button className="apartment medium" onClick={() => setApartment('Lotte')}>Lotte</button>
						<button className="apartment medium" onClick={() => setApartment('Greta')}>Greta</button>
						<button className="apartment medium" onClick={() => setApartment('Anni')}>Anni</button>
						<button className="apartment medium" onClick={() => setApartment('Marie')}>Marie</button>
					</div>
				</div>
			)
		}
		else if (apartment === 'success') return (
			<div className="check-out tac">
				<div className="icon"><IoMdCheckmarkCircle fill='darkgreen' /></div>
				<div className="explain large">{Constants.orderSuccess[prop.lang]}</div>
				<button className="restart button1" onClick={() => window.location.reload(true)}>{Constants.restart[prop.lang]}</button>
			</div>
		)
		else {
			prop.setTitle(Constants.summaryLabel);
			let data = Constants.apartment[prop.lang] + ': ' + apartment + '<br/><br/>';
			prop.cart.map((item, i) => {
				data += item.bread['title_' + prop.lang] + ': ' + item.quantity + 'x<br/>';
			});
			data += '<br/>' + Constants.total[prop.lang] + ': ' + cartTotal;
			return (
				<div className="check-out">
					<div className="summary" dangerouslySetInnerHTML={{ __html: data }}></div>
					<div className="flex-center space-between">
						<button className="abord button1" onClick={() => prop.setTitle(Constants.cartLabel) + prop.setPage('cart') + setApartment('')}>{Constants.stop[prop.lang]}</button>
						<button className="buy button3 t5" onClick={() => sendMail(data)}>{Constants.orderNow[prop.lang]}</button>
					</div>
				</div>
			)
		}
	}

	// BACKEND
	const fetchOrders = async () => {
		// Get all orders
		fetch('https://cockpit.dornsberg.net/api/forms/export/Brot?token=account-9805cfff4b0ec3f5f36f57910991b1', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' }
		})
			.then(bread => bread.json())
			.then(bread => {
				console.log(bread);
				setOrders(bread);
			})
			.catch(function () {
				console.log('Fetch error');
			});
	}

	const Orders = () => (
		orders.map((item, i) => {
			let timestamp = new Date(item._created * 1000);
			return (
				<div key={i} className="purchase">
					<div className="purchase-date">{timestamp.toLocaleDateString('de', { weekday: 'long' })}, {timestamp.toLocaleDateString('de')} um {timestamp.toLocaleTimeString('de')} Uhr</div>
					<div className="purchase-apt">Apartment: <span className="bold medium">{item.data.apartment}</span></div>
					<div className="purchases">
						{item.data.order.map((order, index) => (
							<div key={index} className="order">
								{order.quantity}x {order.name}
							</div>
						))}
					</div>
				</div>
			)
		})
	)

	const Backend = () => {
		if (!orders.length) {
			fetchOrders();
			return <Spinner />;
		}
		else {
			// sort array by timestamp
			orders.sort((a, b) => b._created - a._created)
			return (
				<div className="backend purchases-list">
					<nav className="purchase-filter">
						<div className="filter-title">Apartmentfilter</div>
						<button className="button1" onClick={fetchOrders}>Alle</button>
						<button className="button1" onClick={() => setOrders(orders.filter(item => item.data.apartment === 'Frieda'))}>Frieda</button>
						<button className="button1" onClick={() => setOrders(orders.filter(item => item.data.apartment === 'Paula'))}>Paula</button>
						<button className="button1" onClick={() => setOrders(orders.filter(item => item.data.apartment === 'Martha'))}>Martha</button>
						<button className="button1" onClick={() => setOrders(orders.filter(item => item.data.apartment === 'Franzi'))}>Franzi</button>
						<button className="button1" onClick={() => setOrders(orders.filter(item => item.data.apartment === 'Luise'))}>Luise</button>
						<button className="button1" onClick={() => setOrders(orders.filter(item => item.data.apartment === 'Lotte'))}>Lotte</button>
						<button className="button1" onClick={() => setOrders(orders.filter(item => item.data.apartment === 'Greta'))}>Greta</button>
						<button className="button1" onClick={() => setOrders(orders.filter(item => item.data.apartment === 'Anni'))}>Anni</button>
						<button className="button1" onClick={() => setOrders(orders.filter(item => item.data.apartment === 'Marie'))}>Marie</button>
					</nav>
					<div className='t4 large'>Anzahl Bestellungen: {orders.length}</div>
					<br />
					<Orders />
				</div>
			)
		}
	}

	const Bestseller = () => {
		if (!orders.length) {
			fetchOrders();
			return <Spinner />;
		}
		else {
			let summary = [];
			for (let i = 0; i < orders.length; i++) {
				orders[i].data.order.forEach(function (d) {
					if (summary.hasOwnProperty(d.name)) {
						summary[d.name] = summary[d.name] + d.quantity;
					} else {
						summary[d.name] = d.quantity;
					}
				})
			}
			const sortable = Object.fromEntries(
				Object.entries(summary).sort(([, a], [, b]) => b - a)
			);
			let data = '';
			let index = 1;
			for (const [key, value] of Object.entries(sortable)) {
				data += `<div className="stats-item">${index}. ${key}: ${value}</div>`;
				index++;
			}
			return (
				<div className="bestseller">
					<div className="stats-title t1">Bestseller</div>
					<div className="t3" dangerouslySetInnerHTML={{ __html: data }}></div>
				</div>
			)
		}
	}

	if (loader === true) return <Spinner />
	else if (prop.title === Constants.productNumb) return <BreadList />
	else if (prop.title === Constants.cartLabel) return <CartDetail />
	else if (prop.title === Constants.checkOutLabel || prop.title === Constants.summaryLabel) return <CheckOut />
	else if (prop.title === 'backend') return <Backend />
	else if (prop.title === 'bestseller') return <Bestseller />
	else return <BreadList />

}

export default GetBread;